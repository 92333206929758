<template lang="pug">
  main-el.page(:data-slug="$route.params.page")
    transition(name="pagebody", @enter="$root.$emit('routeEnter')")
      article(v-if="doc")
        //- (title)
        h1.text-20.xl_text-24.px-20.md_px-40.pt-30.md_mb-100(v-if="hasTitle")
          animate-push-on-panel {{ doc.title }}

        nav.hidden.md_block.sticky.top-100.z-10.left-0(role="navigation")
          .absolute.top-0.left-0.pl-40
            ul.w-1x3.text-18
              li(v-for="(item, i) in scrollMenu")
                //- pre {{ item }}
                template(v-if='item.type === "pagesBody_scrollPoint_BlockType"')
                  a(:href="'#' + kb(item.heading)", @click.prevent="e => onScrollLink(e, i)", :data-heading='item.heading') {{ item.heading }}
                template(v-else-if='item.link')
                  a(:href='item.link') {{ item.heading }}
              //- li
                a(href='/') Press

        template(v-if="doc")
          //- pre {{ doc.pagesBody }}
          slice-body(:slices="doc.pagesBody", :templates="templates")

        footer.px-40.pb-40.mt-100.text-12.xl_text-14(v-if="hasCopyright")
          ul.lg_flex
            li.mr-30 ©2024 Roberts Institute of Art
            li.mr-30
              | Design: #[a.btn-theme-text(href="http://apracticeforeverydaylife.com", target="_blank") A Practice for Everyday Life]
            li.mr-30
              | Build: #[a.btn-theme-text(href="http://bin.am", target="_blank") Bin Studio]
</template>

<script>
import { api } from '@/store'
import { queryPage } from '@/graphql/queries'
import SliceBodyText from '@/slices/SliceBodyText'
import SliceNewsletterSignUpRow from '@/slices/SliceNewsletterSignUpRow'
import SliceCallToActionRow from '@/slices/SliceCallToActionRow'
import SliceTypographicAbout from '@/slices/SliceTypographicAbout'
import SliceScrollPoint from '@/slices/SliceScrollPoint'
import PressRecent from '@/components/press/PressRecent'
import kb from 'lodash/kebabCase'
import { scrollTo } from 'vue-scrollto'
export default {
  name: 'Page',
  data () {
    return {
      doc: null,
      templates: {
        pagesBody_typographicAbout_BlockType: SliceTypographicAbout,
        pagesBody_bodyText_BlockType: SliceBodyText,
        pagesBody_scrollPoint_BlockType: SliceScrollPoint,
        pagesBody_callToAction_BlockType: SliceCallToActionRow,
        pagesBody_newsletterSignUp_BlockType: SliceNewsletterSignUpRow,
        pagesBody_press: PressRecent
      }
    }
  },
  computed: {
    scrollMenu () {
      const menu = this.doc?.pagesBody.filter(slice => slice.type === 'pagesBody_scrollPoint_BlockType') || []
      console.log('menu', menu)
      // menu.push({
      //   type: 'link',
      //   heading: 'Press',
      //   link: '/press'
      // })
      menu.push({
        type: 'pagesBody_scrollPoint_BlockType',
        heading: 'Press'
      })
      if (menu.length > 5) {
        const lastItem = menu.pop()
        menu.splice(5, 0, lastItem)
      }
      return menu
    },
    hasTitle () {
      return !(this.doc?.pagesBody.slice(0, 2).find(slice => slice.type === 'pagesBody_typographicAbout_BlockType'))
    },
    hasCopyright () {
      return this.doc.slug === 'contact'
    }
  },
  methods: {
    kb,
    async get () {
      try {
        const resp = await api(queryPage, { slug: this.$route.params.page })
        this.doc = resp.data?.entries[0]
        this.addPressOnAbout()
      } catch (e) {
        console.error(e)
      }
    },
    onScrollLink (e, i) {
      const target = i === 0 ? document.body : e.target.getAttribute('href')
      scrollTo(target, { offset: 0 })
    },
    addPressOnAbout () {
      console.log('addPress')
      if (this.doc.slug === 'about') {
        const index = this.doc.pagesBody.findIndex(slice => slice.heading === 'Opportunities')
        this.doc.pagesBody.splice(index, 0, {
          type: 'pagesBody_press'
          // text: '<h3>Press</h3>'
        })
      }
    }
  },
  created () {
    this.get()
  },
  metaInfo () {
    if (this.doc) {
      const title = this.doc.title
      return {
        title,
        meta: this.$store.getters.meta({
          title,
          descrip: this.doc.metaDescription,
          img: this.doc.metaImage[0]?.url
        })
      }
    }
  }
}
</script>

<style>
.page .slice-body-text{
  @apply mt-50;
}

@media (min-width:900px) {
  .page .slice-body-text{
    /*@apply my-90;*/
    @apply mt-140; /* factor in scroll pt clamping margin with .pt-px */
  }
  .page .call-to-action-row {
    @apply mt-140;
  }
  .page[data-slug="contact"] {
    & .slice-body-text {
      /*@apply my-100;*/
      & > *{
        @apply w-7x12 ml-3x12
      }
    }
  }
}

.pagebody-enter-active{
  transition: opacity 800ms;
}
.pagebody-enter{
  opacity:0;
}
</style>
